export function RestApiTest(intl) {
  return {
    'base_url': [intl.formatMessage({id: "connector.driver.rest_api.test.base_url", defaultMessage: "Connection test failed, check settings."})],
    'auth_endpoint': [intl.formatMessage({id: "connector.driver.rest_api.test.base_url", defaultMessage: "Connection test failed, check settings."})],
    'auth_type': [intl.formatMessage({id: "connector.driver.rest_api.test.base_url", defaultMessage: "Connection test failed, check settings."})],
    'auth_request_data': [intl.formatMessage({id: "connector.driver.rest_api.test.base_url", defaultMessage: "Connection test failed, check settings."})],
    'auth_token_path': [intl.formatMessage({id: "connector.driver.rest_api.test.base_url", defaultMessage: "Connection test failed, check settings."})],
  }
}

export default function RestApi(intl) {
  return [
    {
      field: "base_url",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.rest_api.base_url", defaultMessage: "Base URL for the API"}),
    },
    {
      field: "auth_endpoint",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.rest_api.auth_endpoint", defaultMessage: "Authentication Endpoint"}),
      help: intl.formatMessage({
        id: "connector.driver.rest_api.auth_endpoint_help",
        defaultMessage: "Leave empty to disable authentication before calling data endpoint"
      }),
    },
    {
      field: "auth_type",
      initial: "json",
      label: intl.formatMessage({
        id: "connector.driver.rest_api.auth_type",
        defaultMessage: "Authentication type"
      }),
      options: [
        {id: "json", name: "POST with JSON"},
        {id: "form", name: "POST as FORM"},
        {id: "get", name: "GET with data as query"},
      ]
    },
    {
      field: "auth_request_data",
      initial: "{}",
      lines: 8,
      label: intl.formatMessage({id: "connector.driver.rest_api.auth_request_data", defaultMessage: "Authentication Data (in JSON format)"}),
      help: intl.formatMessage({
        id: "connector.driver.rest_api.filepath_help",
        defaultMessage: 'JSON should be written like: {"username": "123456789", "password": "123456789"}'
      }),
    },
    {
      field: "auth_token_path",
      initial: "access_token",
      label: intl.formatMessage({id: "connector.driver.rest_api.auth_token_path", defaultMessage: "Path to authentication token in JSON response"}),
      help: intl.formatMessage({
        id: "connector.driver.rest_api.json_path_help",
        defaultMessage: "JSON path is written in dot notation, like: content.access_token"
      }),
    },
    {
      field: "auth_token_ttl",
      initial: 0,
      type: "number",
      label: intl.formatMessage({id: "connector.driver.rest_api.auth_token_ttl", defaultMessage: "Authentication token needs to be refreshed every n minutes"}),
      help: intl.formatMessage({
        id: "connector.driver.rest_api.auth_token_ttl_help",
        defaultMessage: "Leave as 0 to disable automatic token refresh while fetching data."
      }),
    },
    {
      field: "data_endpoint",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.rest_api.data_endpoint", defaultMessage: "Data endpoint"}),
    },
    {
      field: "data_query_page",
      initial: "page",
      label: intl.formatMessage({id: "connector.driver.rest_api.data_query_page", defaultMessage: "Page parameter name used in request query, for paging through data"}),
    },
    {
      field: "data_query_limit",
      initial: "size",
      label: intl.formatMessage({id: "connector.driver.rest_api.data_query_limit", defaultMessage: "Page size parameter name used in request query, for paging through data"}),
    },
    {
      field: "data_query",
      initial: "",
      lines: 8,
      label: intl.formatMessage({id: "connector.driver.rest_api.data_query", defaultMessage: "Base query used for fetching data in GET request (url encoded)"}),
      help: intl.formatMessage({
        id: "connector.driver.rest_api.data_query_help",
        defaultMessage: 'Useful for giving extra filter parameters etc. Like: language=en&countries=DK&statuses=PRE%20RELEASED&statuses=ACTIVE'
      }),
    },
    {
      field: "data_root_path",
      initial: "",
      label: intl.formatMessage({id: "connector.driver.rest_api.data_root_path", defaultMessage: "JSON path to root element in data"}),
      help: intl.formatMessage({
        id: "connector.driver.rest_api.json_path_help",
        defaultMessage: "JSON path is written in dot notation, like: content.access_token"
      }),
    },
    {
      field: "data_count_path",
      initial: "totalElements",
      label: intl.formatMessage({id: "connector.driver.rest_api.data_count_path", defaultMessage: "JSON path to element with a count of data in query"}),
      help: intl.formatMessage({
        id: "connector.driver.rest_api.json_path_help",
        defaultMessage: "JSON path is written in dot notation, like: content.access_token"
      }),
    }
  ];
}
