import {authUser} from 'contexts/Auth';
import {Link} from '@material-ui/core';

export default function CsvFile(intl) {
  console.log('Blad')
  return [
    {
      field: "url",
      initial: "",
      label: intl.formatMessage({
        id: "connector.driver.csv_file.host",
        defaultMessage: "Full URL of the file to be imported"
      }),
      help: intl.formatMessage({
        id: "connector.driver.csv_file.url",
        defaultMessage: "To import a file from a webserver specify the full url of the file, example: https://example.com/test.xml",
      }, {
        schema: authUser().site.schema,
      }),
    },
    {
      field: "base_url",
      initial: "",
      label: intl.formatMessage({
        id: "connector.driver.csv_file.base_url.label",
        defaultMessage: "Detect subfolders of files from this base url"
      }),
      help: intl.formatMessage({id: "connector.driver.csv_file.base_url.help", defaultMessage: "A base url of: 'https://example.com/images/folder1/tests/test.xml', could be: 'https://example.com/images/'"}),
    },
    {
      field: "filetype",
      initial: "csv",
      label: intl.formatMessage({id: "connector.driver.csv_file.filetype", defaultMessage: "Filetype"}),
      options: [
        {id: "csv", name: "Comma separated file"},
        {id: "ssv", name: "Semicolon separated file"},
        {id: "tsv", name: "Tab separated file"},
      ]
    },
    {
      field: "field_separator",
      initial: "csv",
      label: intl.formatMessage({id: "connector.driver.csv_file.field_separator", defaultMessage: "Field separator"}),
      options: [
        {id: ',', name: 'Comma ","'},
        {id: ';', name: 'Semicolon ";"'},
        {id: ':', name: 'Colon ";"'},
        {id: '|', name: 'Pipe "|"'},
        {id: ' ', name: 'Space " "'},
      ]
    },
    {
      field: "header",
      initial: "yes",
      label: intl.formatMessage({
        id: "connector.driver.csv_file.header",
        defaultMessage: "Does the file have a header row?"
      }),
      options: [
        {id: "yes", name: "There is a header, use it as reference"},
        {id: "no", name: "No header, first line is data"},
      ]
    },
    {
      field: "filename",
      initial: "",
      label: intl.formatMessage({
        id: "connector.driver.csv_file.filename",
        defaultMessage: "Filename of the exported file"
      }),
    },
    {
      field: "filename_link",
      initial: "",
      render: (_key, data) => {
        return <Link key={"connector_filename_key_" + _key}
                     href={"https://cdn.opendims.com/" + authUser().site.schema + "/exports/" + data.settings_filename}
                     target="_blank">{intl.formatMessage({
          id: "connector.driver.csv_file.download_link",
          defaultMessage: "Download link to exported file"
        })}</Link>
      }
    },
  ];
}
