import {createContext, useEffect, useState} from "react";
import {graphQLApi} from "../services/GraphQLApi";
import {authUser, useAuthDispatch} from "./Auth";
import moment from "moment/moment";

export const SettingsContext = createContext({
  changelog: [],
  settings: {},
  setSetting: () => {},
  setUserSetting: () => {},
  siteSubscriptions: [],
  subscriptions: [],
  refresh: () => {},
  refreshing: false,
});

export const SettingsProvider = ({children}) => {
  const [refreshing, setRefreshing] = useState(false);
  const [settings, setSettings] = useState({});
  const [changelog, setChangelog] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [siteSubscriptions, setSiteSubscriptions] = useState([]);
  const client = new graphQLApi(useAuthDispatch());

  useEffect(() => {
    if (!authUser().id || !refreshing) return;
    client.query('{' +
      ' subscriptions{id type_id type title feature_list automation_id limit price price_currency}' +
      ' siteSubscriptions{data{id subscription_id type_id use_automation renewed_at created_at updated_at}}' +
      ' changelog{version title info description images featured_image released_at}' +
      ' settings{data{key locale type data}}' +
      ' userSettings(filter:{user_id:' + authUser().id + '}){data{id key value}}' +
      '}').then(r => {
      let newSettings = {user: {}};
      if (r?.hasOwnProperty('settings')) {
        r.settings.data.forEach(s => newSettings[s.key] = s.type === 'json' ? JSON.parse(s.data) : s.data);
      }
      if (r?.hasOwnProperty('userSettings')) {
        r.userSettings.data.forEach(s => newSettings.user[s.key] = JSON.parse(s.value));
      }
      if (r.hasOwnProperty('changelog')) {
        setChangelog(r.changelog.filter(v => !!v.released_at && moment(v.released_at).isSameOrBefore()));
      }
      if (r.hasOwnProperty('subscriptions')) {
        setSubscriptions(r.subscriptions);
      }
      if (r.hasOwnProperty('siteSubscriptions')) {
        setSiteSubscriptions(r.siteSubscriptions.data);
      }
      setSettings(newSettings);
      setRefreshing(false);
    });
  }, [refreshing]);
  useEffect(() => {
    setRefreshing(true);
  }, []);

  const setSetting = (key, value) => {
    setSettings({...settings, [key]: value});
  }

  const setUserSetting = (key, value) => {
    const data = {
      user_id: authUser().id,
      key: key,
      value: JSON.stringify(value),
    };
    const vars = {
      user_id: "ID!",
      key: "String",
      value: "String",
    };
    client
      .mutation('userSetting', vars, data, 'id')
      .then(r => {
        if (r && r.hasOwnProperty('response')) {
          setSettings({...settings, user: {...settings.user, [key]: value}});
        }
      });
  }
  const refresh = () => setRefreshing(true);

  return <SettingsContext.Provider value={{
    changelog,
    settings,
    setSetting,
    setUserSetting,
    siteSubscriptions,
    subscriptions,
    refresh,
    refreshing,
  }}>{children}</SettingsContext.Provider>;
}
